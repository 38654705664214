import { Controller } from '@hotwired/stimulus'
import * as basicScroll from 'basicscroll'

export default class extends Controller {
  static targets = [
    'parallax',
    'parafade'
  ]

  parallaxTargetConnected(p) {
    p.classList.add('parallax')
    let distance = 10
    if ('distance' in p.dataset) {
      distance = p.dataset.distance
    }
    let magnitude = window.innerHeight / 200
    basicScroll.create({
      elem: p,
      from: 0,
      to: 'bottom-top',
      direct: true,
      props: {
        '--tw-translate-y': {
          from: 0,
          to: `-${ magnitude * distance }px`
        }
      }
    }).start()
  }

  parafadeTargetConnected(p) {
    p.classList.add('parafade')
    basicScroll.create({
      elem: p,
      from: 'top-bottom',
      to: 'top-middle',
      direct: true,
      props: {
        'opacity': {
          from: 0,
          to: 1
        }
      }
    }).start()
  }

  parashadowTargetConnected(p) {
    p.classList.add('parafade')
    basicScroll.create({
      elem: p,
      from: 'top-bottom',
      to: 'middle-middle',
      direct: true,
      props: {
        '--shadow-length': {
          from: '100px',
          to: '10px'
        },
        '--shadow-y': {
          from: '100px',
          to: '10px'
        },
        '--shadow-z': {
          from: '-100px',
          to: '-10px'
        }
      }
    }).start()
  }

  scrollTo(e) {
    let element = document.querySelector(e.params.element)
    if (element) {
      document.querySelector(e.params.element).scrollIntoView({ behavior: 'smooth' })
    } else {
      window.location.href = `${window.location.origin}${e.params.element}`
    }
  }
}
