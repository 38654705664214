import "$styles/index.css"
// import * as Turbo from "@hotwired/turbo"
import { Application } from "@hotwired/stimulus"

import { Fancybox } from "@fancyapps/ui"
import "@fancyapps/ui/dist/fancybox/fancybox.css"
Fancybox.bind("[data-fancybox]", {
  Toolbar: {
    display: {
      left: [],
      middle: [],
      right: ["close"],
    },
  },
  Thumbs: {
    type: "classic",
  },
  Hash: false
})

/**
 * Adds support for declarative shadow DOM. Requires your HTML <head> to include:
 * `<meta name="turbo-cache-control" content="no-cache" />`
 */
// import * as TurboShadow from "turbo-shadow"

// Import all JavaScript & CSS files from src/_components
import components from "$components/**/*.{js,jsx,js.rb,css}"

window.Stimulus = Application.start()

import Dialog from '@stimulus-components/dialog'
Stimulus.register('dialog', Dialog)

import controllers from "./controllers/**/*.{js,js.rb}"
Object.entries(controllers).forEach(([filename, controller]) => {
  if (filename.includes("_controller.") || filename.includes("-controller.")) {
    const identifier = filename.replace("./controllers/", "")
      .replace(/[_-]controller\..*$/, "")
      .replace(/_/g, "-")
      .replace(/\//g, "--")

    Stimulus.register(identifier, controller.default)
  }
})
