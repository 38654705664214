import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    "star",
    "container"
  ]

  connect() {
    // guaranteed stars
    // right side
    this.appendStar(Math.floor(Math.random() * 10) + 450, Math.floor(Math.random() * 10) + 100, false)
    this.appendStar(Math.floor(Math.random() * 10) + 320, Math.floor(Math.random() * 5) + 105, false)
    this.appendStar(Math.floor(Math.random() * 10) + 480, Math.floor(Math.random() * 10) + 150, false)
    this.appendStar(Math.floor(Math.random() * 10) + 500, Math.floor(Math.random() * 10) + 350, false)
    // left side
    this.appendStar(0 - (Math.floor(Math.random() * 10) + 450), Math.floor(Math.random() * 10) + 100, false)
    this.appendStar(0 - (Math.floor(Math.random() * 10) + 320), Math.floor(Math.random() * 5) + 105, false)
    this.appendStar(0 - (Math.floor(Math.random() * 10) + 480), Math.floor(Math.random() * 10) + 150, false)
    this.appendStar(0 - (Math.floor(Math.random() * 10) + 500), Math.floor(Math.random() * 10) + 325, false)

    // random stars
    var star_count = Math.floor(Math.random() * 6) + 4
    for (var i = 0; i < star_count; i++) {
      var x = Math.floor(Math.random() * 300) + 600 + (i * 10)
      var y = Math.floor(Math.random() * 200) + 200 + (i * 10)
      var big = false
      if (i < 2) {
        big = true
      }
      if (i % 2 == 0) {
        x = 0 - x - 20
      }
      this.appendStar(x, y, big)
    }
  }

  appendStar(x, y, big) {
    var star = 6
    if (!big) {
      star = Math.floor(Math.random() * 5) + 1
    }
    var delay = Math.floor(Math.random() * 6) + 1
    var distance = Math.floor(Math.random() * 20) + 10
    y += (distance * 2)
    var element = document.createElement('img')
    element.setAttribute('class', 'absolute opacity-50 animate-pulse motion-safe:translate-y-0')
    element.setAttribute('data-distance', distance)
    element.setAttribute('data-scroll-target', 'parallax')
    element.setAttribute('src', `/assets/images/star${star}.png`)
    element.setAttribute('style', `margin-left: ${x}px; margin-top: ${y}px; animation-delay: -${delay}s`)
    element.setAttribute('aria-hidden', 'true')
    this.containerTarget.append(element)
  }
}
