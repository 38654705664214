import { Controller } from '@hotwired/stimulus'
export default class extends Controller {
  static targets = [
    'date',
    'countdown'
  ]
  static values = {
    time: { type: String, default: '' },
    cooldown: { type: Number, default: 0 },
    started: { type: Boolean, default: false },
    locale: { type: String, default: 'en_US' },
    source: { type: String, default: 'https://files.deltarune.com/is-it-ready-yet.txt' },
    timeSinceRefresh: { type: Number, default: 0 }
  }

  connect() {
    this.localeValue = document.querySelector('meta[property="og:locale"]').content
    this.getLatestCountdown()
  }

  updateCountdown() {
    this.timeSinceRefreshValue += 1
    if (this.timeSinceRefreshValue >= 300) {
      this.timeSinceRefreshValue = 0
      this.getLatestCountdown()
    }
    let now = new Date().getTime()
    let endTime = new Date(this.timeValue)

    let distance = endTime.getTime() - now
    let days = Math.floor(distance / (1000 * 60 * 60 * 24))
    if (days < 10 && days > 0) {
      days = `0${days}`
    }
    let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    if (hours < 10 && hours >= 0) {
      hours = `0${hours}`
    }
    let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
    if (minutes < 10 && minutes >= 0) {
      minutes = `0${minutes}`
    }
    if (minutes == 0) {
      minutes = '00'
    }
    let seconds = Math.floor((distance % (1000 * 60)) / 1000)
    if (seconds < 10) {
      seconds = `0${seconds}`
    }

    if (distance < 0) {
      this.dateTarget.innerHTML = "NOW"
      this.countdownTarget.innerHTML = "(REFRESH)"
      setTimeout(this.getLatestCountdown.bind(this), this.cooldownValue)
      this.cooldownValue = 15000
    } else {
      if (this.localeValue == 'ja_JP') {
        this.dateTarget.innerHTML = `${this.timeValue.substring(0, 4)}年${this.timeValue.substring(6, 7)}月${this.timeValue.substring(9, 10)}日　リリース`
      } else {
        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
        this.dateTarget.innerHTML = `Releasing on ${months[endTime.getMonth()]} ${this.timeValue.substring(9, 10)}, ${this.timeValue.substring(0, 4)}`
      }
      this.countdownTarget.innerHTML = `<div class='flex gap-x-2 items-center justify-center'>${days > 0 ? "<span class='text-3xl whitespace-nowrap md:text-6xl min-w-[50px] md:min-w-[86px] text-right'>" + days + "</span><span class='text-2xl md:text-5xl w-4 md:w-8'>:</span>" : ""}${(days > 0 || hours > 0) ? "<span class='text-3xl whitespace-nowrap md:text-6xl min-w-[50px] md:min-w-[86px] text-right'>" + hours + "</span><span class='text-2xl md:text-5xl w-4 md:w-8'>:</span>" : ""}<span class='text-3xl whitespace-nowrap md:text-6xl min-w-[50px] md:min-w-[86px] text-right'>${minutes}</span><span class='text-2xl md:text-5xl w-4 md:w-8'>:</span><span class='text-3xl whitespace-nowrap md:text-6xl min-w-[50px] md:min-w-[86px] text-right'>${seconds}</span></div>`
    }
  }

  getLatestCountdown() {
    fetch(this.sourceValue, { cache: 'no-store' })
    .then(response => response.text())
    .then(data => {
      if (data == 'NO') {
        // it is not time
        this.startCountdown()
      } else if (data.includes('https')) {
        // window.location.replace(data)
      } else {
        this.timeValue = data
        this.startCountdown()
      }
    })
    .catch(error => {
      this.startCountdown()
    })
  }

  startCountdown() {
    this.updateCountdown()
    if (!this.startedValue) {
      setInterval(this.updateCountdown.bind(this), 1000)
      this.startedValue = true
    }
  }
}
