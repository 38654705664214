import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    "particle",
    "container",
    "mobile"
  ]

  connect() {
    if (this.hasContainerTarget) {
      // random particles
      var particle_count = Math.floor(Math.random() * 4) + 6
      for (var i = 0; i < particle_count; i++) {
        var x = Math.floor(Math.random() * 600) + 600 + (i * 10)
        var y = Math.floor(Math.random() * 450) + 200 + (i * 10)
        if (i % 2 == 0) {
          x = 0 - x - 20
        }
        this.appendParticle(x, y, this.containerTarget)
      }
      var pixel_count = Math.floor(Math.random() * 4) + 8
      for (var i = 0; i < pixel_count; i++) {
        var x = Math.floor(Math.random() * 600) + 800 + (i * 10)
        var y = Math.floor(Math.random() * 450) + 200 + (i * 10)
        if (i % 2 == 0) {
          x = 0 - x - 20
        }
        this.appendPixel(x, y, this.containerTarget)
      }
    }
    if (this.hasMobileTarget) {
      // random particles
      var particle_count = Math.floor(Math.random() * 2) + 4
      for (var i = 0; i < particle_count; i++) {
        var x = Math.floor(Math.random() * 100) + 220 + (i * 10)
        var y = Math.floor(Math.random() * 300) + 100 + (i * 10)
        if (i % 2 == 0) {
          x = 0 - x - 20
        }
        this.appendParticle(x, y, this.mobileTarget, 3)
      }
      var pixel_count = Math.floor(Math.random() * 3) + 6
      for (var i = 0; i < pixel_count; i++) {
        var x = Math.floor(Math.random() * 100) + 220 + (i * 10)
        var y = Math.floor(Math.random() * 300) + 100 + (i * 10)
        if (i % 2 == 0) {
          x = 0 - x - 20
        }
        this.appendPixel(x, y, this.mobileTarget, 2)
      }
    }
  }

  appendParticle(x, y, container, maxWidth=12) {
    var particle = Math.floor(Math.random() * 3) + 1
    if (x > 0) {
      particle += 3
    }
    var delay = Math.floor(Math.random() * 6) + 1
    var distance = Math.floor(Math.random() * 80) + 50
    y += (distance * 2)
    var element = document.createElement('img')
    element.setAttribute('class', 'absolute opacity-50 animate-pulse motion-safe:translate-y-0')
    element.setAttribute('data-distance', distance)
    element.setAttribute('data-scroll-target', 'parallax')
    element.setAttribute('src', `/assets/images/particle-${particle}.png`)
    element.setAttribute('style', `margin-left: ${x}px; margin-top: ${y}px; animation-delay: -${delay}s; max-width: ${maxWidth}px`)
    element.setAttribute('aria-hidden', 'true')
    container.append(element)
  }

  appendPixel(x, y, container, sizeRoll=4) {
    var size = Math.floor(Math.random() * sizeRoll) + 2
    var delay = Math.floor(Math.random() * 6) + 1
    var distance = Math.floor(Math.random() * 100) + 50
    var brightness = Math.floor(Math.random() * 200)
    y += (distance * 2)
    var element = document.createElement('span')
    element.setAttribute('class', 'absolute animate-pulse motion-safe:translate-y-0 border-0')
    element.setAttribute('data-distance', distance)
    element.setAttribute('data-scroll-target', 'parallax')
    element.setAttribute('style', `margin-left: ${x}px; margin-top: ${y}px; width: ${size}px; height: ${size}px; background-color: rgb(${brightness}, ${brightness}, 255); animation-delay: -${delay}s`)
    element.setAttribute('aria-hidden', 'true')
    container.append(element)
  }
}
