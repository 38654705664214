
        import * as module0 from './controllers/audio_controller.js';import * as module1 from './controllers/countdown_controller.js';import * as module2 from './controllers/particles_controller.js';import * as module3 from './controllers/scroll_controller.js';import * as module4 from './controllers/stars_controller.js';import * as module5 from './controllers/video_controller.js'
        const modules = {
            "./controllers/audio_controller.js": module0,
            "./controllers/countdown_controller.js": module1,
            "./controllers/particles_controller.js": module2,
            "./controllers/scroll_controller.js": module3,
            "./controllers/stars_controller.js": module4,
            "./controllers/video_controller.js": module5,
        };
        export default modules;
      